import $ from 'jquery';
import Backbone from 'backbone';

const StatusUpdate = Backbone.View.extend({
  events: {
    'click .js-ask-update-status': 'prepareDataForUpdate',
    'click .js-dismiss-update-status': 'dismissUpdate',
    'click .js-confirm-update-status': 'confirmUpdate',
  },
  prepareDataForUpdate(event) {
    const tableRow = $(event.currentTarget).closest(
      '.js-campaign-row, tr[data-campaign-id]'
    );
    this._campaignType = tableRow.data('campaign-type');
    this._campaignId = tableRow.data('campaign-id');
    this._updateTo = $(event.currentTarget).data('new-status');
  },
  dismissUpdate() {
    delete this._campaignType;
    delete this._campaignId;
    delete this._updateTo;
  },
  confirmUpdate() {
    const sendData = {
      id: this._campaignId,
      campaignType: this._campaignType,
      field: 'status',
      value: this._updateTo,
    };
    trovitApp.ajax.call({
      url: '/index.php/cod.campaigns_quick_edit',
      data: sendData,
      success(json) {
        if (json.success) {
          window.location.reload(true);
        } else {
          $('.js-active-campaign-modal').modal('hide');
        }
      },
    });
  },
});
export default StatusUpdate;
