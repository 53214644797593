// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BannerIssueThreeDecimals{
    margin-bottom: 0.5em;
}`, "",{"version":3,"sources":["webpack://./js/components/BannerIssueThreeDecimals/BannerIssueThreeDecimals.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;AACxB","sourcesContent":[".BannerIssueThreeDecimals{\n    margin-bottom: 0.5em;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
