import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { fixDecimalsFor } from 'components/UnifiedCampaignForm/fixDecimalsFor';
import { cookies } from 'reffects-batteries';

export default function registerEvents() {
  registerEventHandler(
    'FORMAT_INPUT_DECIMALS',
    function updateCampaignEditFormData(_, { name, value, decimalsConfig }) {
      return {
        dispatch: {
          id: 'UPDATE_CAMPAIGN_FORM',
          payload: {
            [name]: fixDecimalsFor(value, decimalsConfig),
          },
        },
      };
    }
  );

  registerEventHandler(
    'UPDATE_CAMPAIGN_CPC',
    function updateCampaignCpc(
      { state: { isCustomCpcMobile } },
      { cpc: newCpc }
    ) {
      if (isCustomCpcMobile) {
        return {
          dispatch: {
            id: 'UPDATE_CAMPAIGN_FORM',
            payload: {
              cpc: newCpc,
            },
          },
        };
      }
      return {
        dispatch: {
          id: 'UPDATE_CAMPAIGN_FORM',
          payload: {
            cpc: newCpc,
            cpcMobile: newCpc,
          },
        },
      };
    },
    [state.get({ isCustomCpcMobile: 'isCustomCpcMobile' })]
  );

  registerEventHandler(
    'SET_CUSTOM_CPC_MOBILE_OPTION',
    function toggleCustomCpcMobile(
      { state: { currentCpc } },
      { isCustomCpcMobile }
    ) {
      if (!isCustomCpcMobile) {
        return state.set({
          isCustomCpcMobile,
          'formData.cpcMobile': currentCpc,
        });
      }

      return state.set({
        isCustomCpcMobile,
      });
    },
    [state.get({ currentCpc: 'formData.cpc' })]
  );

  registerEventHandler(
    'UPDATE_CAMPAIGN_CPS',
    function updateCampaignCps(
      { state: { isCustomCpsMobile } },
      { cps: newCps }
    ) {
      if (isCustomCpsMobile) {
        return {
          dispatch: {
            id: 'UPDATE_CAMPAIGN_FORM',
            payload: {
              cps: newCps,
            },
          },
        };
      }
      return {
        dispatch: {
          id: 'UPDATE_CAMPAIGN_FORM',
          payload: {
            cps: newCps,
            cpsMobile: newCps,
          },
        },
      };
    },
    [state.get({ isCustomCpsMobile: 'isCustomCpsMobile' })]
  );

  registerEventHandler(
    'SET_CUSTOM_CPS_MOBILE_OPTION',
    function toggleCustomCpsMobile(
      { state: { currentCps } },
      { isCustomCpsMobile }
    ) {
      if (!isCustomCpsMobile) {
        return state.set({
          isCustomCpsMobile,
          'formData.cpsMobile': currentCps,
        });
      }

      return state.set({
        isCustomCpsMobile,
      });
    },
    [state.get({ currentCps: 'formData.cps' })]
  );

  registerEventHandler(
    'DISMISS_THREE_DECIMALS_POPUP',
    function dismissThreeDecimalsPopup() {
      return {
        ...state.set({
          isDismissedDecimalPrecision: true,
        }),
        ...cookies.set({
          key: 'decimalPrecisionDismissed',
          value: true,
        }),
      };
    }
  );

  registerEventHandler(
    'INITIALIZE_DECIMAL_PRECISION_POPUP',
    function initializeDecimalPrecisionPopup({
      cookie: { decimalPrecisionDismissed },
    }) {
      const isSetDecimalPrecisionCookie =
        typeof decimalPrecisionDismissed !== 'undefined';
      return {
        ...state.set({
          isDismissedDecimalPrecision: isSetDecimalPrecisionCookie,
        }),
      };
    },
    [cookies.get('decimalPrecisionDismissed')]
  );
}
