import React from 'react';
import { subscribe } from 'reffects-store';
import ClosableBanner from 'components/library/Banner/ClosableBanner';
import 'BannerIssueThreeDecimals.css';

export default subscribe(
  function BannerIssueThreeDecimals({ showBannerIssueThreeDecimals }) {
    return (
      <>
        {showBannerIssueThreeDecimals && (
          <ClosableBanner
            className="Banner--legacy BannerIssueThreeDecimals"
            color="warning"
            crossIconWidth={20}
            shown
            variant="regular"
          >
            <b>Heads up!</b> The cost for the days January 3rd, 4th and 5th 2025
            may be incorrect for some Maximize Conversion, Smart Clicks, and
            Lead Gen campaigns. We are working to apply the correct value.
            Apologies for the error.
          </ClosableBanner>
        )}
      </>
    );
  },
  function mapStateToProps(state) {
    return {
      showBannerIssueThreeDecimals: true,
    };
  }
);
