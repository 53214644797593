import $ from 'jquery';
import _ from 'underscore';
import _s from 'underscore.string';
import Backbone from 'backbone';
import 'highcharts';
import 'jquery-ui/ui/widgets/datepicker';
import 'datatables.net';
import 'bootstrap/js/tooltip';
import ReactDOM from 'react-dom';
import Button from 'components/library/Button/Button';
import SpinnerIcon from 'components/library/Icons/SpinnerIcon';
import DownloadIcon from 'components/library/Icons/DownloadIcon';
import React from 'react';
import DownloadLeadsIcon from 'components/library/Icons/DownloadLeadsIcon';

const HOMES_VERTICAL_ID = 1;

const ReportTable = Backbone.View.extend({
  leadsDownloading: false,
  statsDownloading: false,
  renderExportCsv(isDownloading = false) {
    const isEnabled = !isDownloading;
    ReactDOM.render(
      <Button
        color="primary"
        variant="stroked"
        onClick={() => {
          this.exportToCsv();
        }}
        disabled={isDownloading || !isEnabled}
      >
        {isDownloading ? <SpinnerIcon /> : <DownloadIcon />}
        {trovitApp.i18n('traffic_export_to_csv')}
      </Button>,
      this.$('.js-export-to-csv').get(0)
    );
  },
  exportToCsv() {
    this.statsDownloading = true;
    const that = this;
    const params = this.prepareExportParams();
    const searchParams = new URLSearchParams(
      params.substring(params.indexOf('?'))
    );
    const dToken = searchParams.get('dToken');
    let attempts = 30;
    this.renderExportCsv(true);
    const downloadTimer = window.setInterval(function downloadTimerCallback() {
      const token = that._getCookie('dToken');
      if (token === dToken || attempts-- === 0) {
        window.clearInterval(downloadTimer);
        that.renderExportCsv(false);
        this.statsDownloading = false;
      }
    }, 1000);

    window.location.href = `/index.php/cod.campaigns_manual_cpc_report_export?${params}`;
  },
  renderExportEnquiriesCsv(isDownloading = false) {
    const isEnabled = !isDownloading;
    ReactDOM.render(
      <Button
        color="primary"
        variant="stroked"
        onClick={() => {
          this.exportEnquiriesToCsv();
        }}
        disabled={isDownloading || !isEnabled}
      >
        {isDownloading ? <SpinnerIcon /> : <DownloadLeadsIcon />}
        {trovitApp.i18n('traffic_export_leads_to_csv')}
      </Button>,
      this.$('.js-export-leads-to-csv').get(0)
    );
  },
  exportEnquiriesToCsv() {
    this.leadsDownloading = true;
    const that = this;
      const params = {
      dToken: new Date().getTime(),
      startDate: this.model.get('startDate'),
      endDate: this.model.get('endDate'),
      device: this.model.get('device'),
      campaign: this.model.get('campaignId'),
    };
    let attempts = 30;

    if (this.shouldShowExportCsvButton) {
      this.renderExportEnquiriesCsv(true);
    }

    const downloadTimer = window.setInterval(function downloadTimerCallback() {
      const token = that._getCookie('dToken');
      if (parseInt(token, 10) === params.dToken || attempts-- === 0) {
        window.clearInterval(downloadTimer);
        that.renderExportEnquiriesCsv(false);
        this.leadsDownloading = false;
      }
    }, 1000);

    window.location.href = `/index.php/api/campaign/enquiries?${$.param(
      params
    )}`;
  },
  _getCookie(name) {
    const parts = document.cookie.split(`${name}=`);
    if (parts.length == 2) return parts.pop().split(';').shift();
  },
  _redrawData(fullRedraw) {
    const currentListData = this.model.get('list');
    if (fullRedraw) {
      this._fillList(currentListData.lines);
      this._fillListTotals(currentListData.totals);
    }
    this.trigger('redrawn');
  },
  _fillList(lines) {
    if (this._table) {
      this._table.destroy();
    }
    const list = this.$el.find('.js-graph-lines').empty();
    const line = _.template($('script.js-traffic-line-template').html());
    if (_.isEmpty(lines)) {
      return false;
    }
    _.each(
      lines,
      function (values) {
        const params = _.clone(values);
        let row;
        params.strCpc = _s.numberFormat(
          values.cpc ? values.cpc : 0,
          trovitData.cpcDecimals,
          trovitApp.i18n('js_decimals_char'),
          trovitApp.i18n('js_thousands_char')
        );
        params.strCpcEur = _s.numberFormat(
          values.cpcEur ? values.cpcEur : 0,
          2,
          trovitApp.i18n('js_decimals_char'),
          trovitApp.i18n('js_thousands_char')
        );
        params.strBudget = _s.numberFormat(
          values.budget ? values.budget : 0,
          0,
          trovitApp.i18n('js_decimals_char'),
          trovitApp.i18n('js_thousands_char')
        );
        params.strBudgetEur = _s.numberFormat(
          values.budgetEur ? values.budgetEur : 0,
          0,
          trovitApp.i18n('js_decimals_char'),
          trovitApp.i18n('js_thousands_char')
        );
        params.strClicks = _s.numberFormat(
          values.clicks ? values.clicks : 0,
          0,
          trovitApp.i18n('js_decimals_char'),
          trovitApp.i18n('js_thousands_char')
        );
        params.strFreeClicks = _s.numberFormat(
          values.freeClicks ? values.freeClicks : 0,
          0,
          trovitApp.i18n('js_decimals_char'),
          trovitApp.i18n('js_thousands_char')
        );
        params.strCost = _s.numberFormat(
          values.cost ? values.cost : 0,
          trovitData.cpcDecimals,
          trovitApp.i18n('js_decimals_char'),
          trovitApp.i18n('js_thousands_char')
        );
        params.strCostEur = _s.numberFormat(
          values.costEur ? values.costEur : 0,
          2,
          trovitApp.i18n('js_decimals_char'),
          trovitApp.i18n('js_thousands_char')
        );
        params.strConv = _s.numberFormat(
          values.conv ? values.conv : 0,
          0,
          trovitApp.i18n('js_decimals_char'),
          trovitApp.i18n('js_thousands_char')
        );
        params.strConvRate = _s.numberFormat(
          values.convRate ? values.convRate : 0,
          2,
          trovitApp.i18n('js_decimals_char'),
          trovitApp.i18n('js_thousands_char')
        );
        params.strCpa = _s.numberFormat(
          values.cpa ? values.cpa : 0,
          trovitData.cpcDecimals,
          trovitApp.i18n('js_decimals_char'),
          trovitApp.i18n('js_thousands_char')
        );
        params.strNumAds = _s.numberFormat(
          values.numAds ? values.numAds : 0,
          0,
          trovitApp.i18n('js_decimals_char'),
          trovitApp.i18n('js_thousands_char')
        );
        params.featured = values.notActive ? 'text-danger' : '';
        params.featuredStr = values.notActive ? values.notActiveStr : '';
        params.weekendClass = values.isWeekend ? 'text-muted' : '';
        params.titleOrder = params.titleOrder || params.title;
        params.strStatus = values.status;
        switch (values.statusSlug) {
          case 'active':
            params.statusClass = 'campaign-active';
            break;
          case 'deleted':
            params.statusClass = 'campaign-deleted';
            break;
          case 'paused':
            params.statusClass = 'campaign-paused';
            break;
          case 'pending':
            params.statusClass = 'campaign-pending';
            break;
          default:
            params.statusClass = '';
        }
        params.report = this.model;
        row = line(params);
        list.append(row);
      },
      this
    );
    const numCols = this.$('.js-graph-table-main thead th').length;
    const colDef = [];
    for (let i = 0; i < numCols; i++) {
      colDef.push(null);
    }
    if (this.$('.js-status-th').length > 0) {
      colDef[0] = {
        orderable: false,
      };
    }
    this._table = this.$el.find('.js-graph-table-main').DataTable({
      paginate: false,
      info: false,
      searching: false,
      order: this.$('.js-status-th').length > 0 ? [[4, 'desc']] : [[0, 'asc']],
      columns: colDef,
    });
    if ($('[data-toggle="tooltip"]').length) {
      $('[data-toggle="tooltip"]').tooltip({
        html: true,
      });
    }
  },
  _fillListTotals(totals) {
    const row = this.$el.find('.js-graph-lines-totals');
    if (_.isEmpty(totals)) {
      return false;
    }
    row
      .find('.js-daily-budget')
      .text(
        _s.numberFormat(
          totals.dailyBudget ? totals.dailyBudget : 0,
          2,
          trovitApp.i18n('js_decimals_char'),
          trovitApp.i18n('js_thousands_char')
        )
      );
    row
      .find('.js-monthly-budget')
      .text(
        _s.numberFormat(
          totals.monthlyBudget ? totals.monthlyBudget : 0,
          2,
          trovitApp.i18n('js_decimals_char'),
          trovitApp.i18n('js_thousands_char')
        )
      );
    row
      .find('.js-totals-clicks')
      .text(
        _s.numberFormat(
          totals.clicks ? totals.clicks : 0,
          0,
          trovitApp.i18n('js_decimals_char'),
          trovitApp.i18n('js_thousands_char')
        )
      );
    row
      .find('.js-totals-cost')
      .text(
        _s.numberFormat(
          totals.cost ? totals.cost : 0,
          trovitData.cpcDecimals,
          trovitApp.i18n('js_decimals_char'),
          trovitApp.i18n('js_thousands_char')
        )
      );
    row
      .find('.js-totals-conv')
      .text(
        _s.numberFormat(
          totals.conv ? totals.conv : 0,
          0,
          trovitApp.i18n('js_decimals_char'),
          trovitApp.i18n('js_thousands_char')
        )
      );
    if (!this.isSalesDashboard) {
      row
        .find('.js-totals-conv-rate')
        .text(
          _s.numberFormat(
            totals.convRate ? totals.convRate : 0,
            2,
            trovitApp.i18n('js_decimals_char'),
            trovitApp.i18n('js_thousands_char')
          )
        );
      row
        .find('.js-totals-cpa')
        .text(
          _s.numberFormat(
            totals.cpa ? totals.cpa : 0,
            trovitData.cpcDecimals,
            trovitApp.i18n('js_decimals_char'),
            trovitApp.i18n('js_thousands_char')
          )
        );
    } else {
      row
        .find('.js-totals-free-clicks')
        .text(
          _s.numberFormat(
            totals.freeClicks ? totals.freeClicks : 0,
            0,
            trovitApp.i18n('js_decimals_char'),
            trovitApp.i18n('js_thousands_char')
          )
        );
      row
        .find('.js-daily-budget')
        .attr(
          'title',
          `${_s.numberFormat(
            totals.dailyBudgetEur ? totals.dailyBudgetEur : 0,
            2,
            trovitApp.i18n('js_decimals_char'),
            trovitApp.i18n('js_thousands_char')
          )} EUR`
        )
        .attr(
          'data-original-title',
          `${_s.numberFormat(
            totals.dailyBudgetEur ? totals.dailyBudgetEur : 0,
            2,
            trovitApp.i18n('js_decimals_char'),
            trovitApp.i18n('js_thousands_char')
          )} EUR`
        );
      row
        .find('.js-monthly-budget')
        .attr(
          'title',
          `${_s.numberFormat(
            totals.monthlyBudgetEur ? totals.monthlyBudgetEur : 0,
            0,
            trovitApp.i18n('js_decimals_char'),
            trovitApp.i18n('js_thousands_char')
          )} EUR`
        )
        .attr(
          'data-original-title',
          `${_s.numberFormat(
            totals.monthlyBudgetEur ? totals.monthlyBudgetEur : 0,
            0,
            trovitApp.i18n('js_decimals_char'),
            trovitApp.i18n('js_thousands_char')
          )} EUR`
        );
      row
        .find('.js-totals-cost')
        .attr(
          'title',
          `${_s.numberFormat(
            totals.costEur ? totals.costEur : 0,
            0,
            trovitApp.i18n('js_decimals_char'),
            trovitApp.i18n('js_thousands_char')
          )} EUR`
        )
        .attr(
          'data-original-title',
          `${_s.numberFormat(
            totals.costEur ? totals.costEur : 0,
            0,
            trovitApp.i18n('js_decimals_char'),
            trovitApp.i18n('js_thousands_char')
          )} EUR`
        );
      if (totals.costEur == 0 || totals.clicks == 0) {
        this.$el
          .find('.js-average-cpc')
          .text(
            _s.numberFormat(
              0,
              trovitData.cpcDecimals,
              trovitApp.i18n('js_decimals_char'),
              trovitApp.i18n('js_thousands_char')
            )
          );
      } else {
        this.$el
          .find('.js-average-cpc')
          .text(
            _s.numberFormat(
              totals.costEur / totals.clicks,
              trovitData.cpcDecimals,
              trovitApp.i18n('js_decimals_char'),
              trovitApp.i18n('js_thousands_char')
            )
          );
      }
      if (totals.costEur == 0 || totals.conv == 0) {
        this.$el
          .find('.js-average-cpl')
          .text(
            _s.numberFormat(
              0,
              trovitData.cpcDecimals,
              trovitApp.i18n('js_decimals_char'),
              trovitApp.i18n('js_thousands_char')
            )
          );
      } else {
        this.$el
          .find('.js-average-cpl')
          .text(
            _s.numberFormat(
              totals.costEur / totals.conv,
              trovitData.cpcDecimals,
              trovitApp.i18n('js_decimals_char'),
              trovitApp.i18n('js_thousands_char')
            )
          );
      }
      this.$el
        .find('.js-percent-clickout')
        .text(
          _s.numberFormat(
            totals.clicks ? (totals.paidClicks / totals.clicks) * 100 : 0,
            2,
            trovitApp.i18n('js_decimals_char'),
            trovitApp.i18n('js_thousands_char')
          )
        );
    }
  },
  initialize() {
    this.shouldShowExportCsvButton =
      trovitData.source.verticalId === HOMES_VERTICAL_ID;
    this._lastParams = {};

    this.model.on(
      {
        sync() {
          this._redrawData(true);
        },
        request() {
          this.statsDownloading && this.disableExportToCsvButton();
          this.leadsDownloading && this.disableExportLeadsToCsvButton();
        },
        'sync error': function () {
          this.enableExportToCsvButton();
          this.enableExportLeadsToCsvButton();
        },
      },
      this
    );
    return this;
  },
  enableExportToCsvButton() {
    this.renderExportCsv(false);
  },
  disableExportToCsvButton() {
    this.renderExportCsv(true);
  },
  enableExportLeadsToCsvButton() {
    if (this.shouldShowExportCsvButton) {
      this.renderExportEnquiriesCsv(false);
    }
  },
  disableExportLeadsToCsvButton() {
    if (this.shouldShowExportCsvButton) {
      this.renderExportEnquiriesCsv(true);
    }
  },
  prepareExportParams() {
    const campaignId = new URLSearchParams(window.location.search).get(
      'campaign'
    );
    const type = new URLSearchParams(window.location.search).get(
      'campaignType'
    );
    let queryString = `campaignId=${campaignId}&type=${type}`;
    if (!campaignId) {
      return this;
    }
    queryString +=
      `&startDate=${this.model.get('startDate')}` +
      `&endDate=${this.model.get('endDate')}` +
      `&device=${this.model.get('device')}`;
    return queryString;
  },
});
export default ReportTable;
