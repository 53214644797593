import getFeatureSelector from 'selectors/featureFlags';
import {
  OBJECTIVE_CLICKS,
  OBJECTIVE_LEADS,
  OBJECTIVE_PIXEL_CONVERSIONS,
  OBJECTIVE_SMART_CLICKS,
  VERTICAL_ID_HOMES,
} from 'components/UnifiedCampaignForm/constants';
import { audienceGeoCriteriaSelector } from 'components/UnifiedCampaignForm/AudienceSegmentationForm/selectors';

export function minCpsSelector(state) {
  return parseInt(state.trovitData.minCpcs[state.formData.objective], 10) / 100;
}

/** @TODO: This can be safely removed in favor of minCpcSelector */
export function minCpcPremiumSelector(state) {
  return minCpsSelector(state);
}

export function formDataSelector(state) {
  return state.formData;
}

export function currencySymbolSelector(state) {
  return state.currencySymbol;
}

export function minBudgetSelector(state) {
  return isDailyBudgetSelector(state)
    ? state.minDailyBudget
    : state.minMonthlyBudget;
}

export function isDailyBudgetSelector(state) {
  return state.formData.isDaily;
}

export function isSpecificTargetingSelector(state) {
  return state.isSpecificTargeting;
}

export function isSubmittingSelector(state) {
  return state.isSubmitting;
}

export function isDefaulterSelector(state) {
  return state.isDefaulter;
}

export function hasBillingDataSelector(state) {
  return state.hasBillingData;
}

export function missingBillingDataSelector(state) {
  return !hasBillingDataSelector(state);
}

export function allowsDefiningCampaignSelector(state) {
  return state.hasBillingData && !isDefaulterSelector(state);
}

export function minimumEndDateSelector(state) {
  const {
    formData: { startDateAsString },
    todayAsString,
  } = state;

  if (todayAsString > startDateAsString) {
    return todayAsString;
  }

  return startDateAsString;
}

export function isLoadingSelector({ isLoading }) {
  return isLoading;
}

export function showCardSelector(state) {
  return !isLoadingSelector(state);
}

export function isDuplicatedCampaignSelector(state) {
  return (
    state.originalFormData?.segmentation[0]?.field === 'segmentationFields'
  );
}

function isObjective(state, objective) {
  return state.formData.objective === objective;
}

export function isLeadObjectiveSelector(state) {
  return isObjective(state, OBJECTIVE_LEADS);
}

export function isConversionObjectiveSelector(state) {
  return isObjective(state, OBJECTIVE_PIXEL_CONVERSIONS);
}

export function campaignObjectiveTypeLabelSelector(state) {
  if (isLeadObjectiveSelector(state)) {
    return trovitApp.i18n('create_campaign_objective_lead_label');
  }

  if (isConversionObjectiveSelector(state)) {
    return trovitApp.i18n('create_campaign_objective_conversions_label');
  }

  if (isObjective(state, OBJECTIVE_SMART_CLICKS)) {
    return trovitApp.i18n('create_campaign_objective_smart_clicks_label');
  }

  return trovitApp.i18n('create_campaign_objective_traffic_label');
}

export function isNewCampaignSelector(state) {
  return state.isNew;
}

export function showBudgetStrategySelector(state) {
  return (
    !isNewCampaignSelector(state) && state.originalFormData?.budget.isDaily
  );
}

export function showCampaignObjectiveTypeSelector(state) {
  return isEnabledMoreCampaignObjectivesSelector(state);
}

export function isEnabledMoreCampaignObjectivesSelector(state) {
  return (
    state.trovitData.source.verticalId === VERTICAL_ID_HOMES ||
    isConversionObjectiveEnabledSelector(state)
  );
}

export function isConversionObjectiveEnabledSelector(state) {
  return getFeatureSelector(state, 'ALLOW__CAMPAIGNS_FOR_MAXIMIZE_CONVERSIONS');
}

export function showSmartClicksObjectiveSelector(state) {
  return getFeatureSelector(
    state,
    'ENABLE_SMART_CLICKS_ON_CAMPAIGN_CHOOSER_PAGE'
  );
}

export function isObjectiveClicksSelector(state) {
  return state.formData.objective === OBJECTIVE_CLICKS;
}

export function showCampaignObjectiveChooserSelector(state) {
  return (
    (state.openUnifiedForm === undefined || state.openUnifiedForm === false) &&
    isCampaignObjectiveChooserEnabledSelector(state)
  );
}
export function isCampaignObjectiveChooserEnabledSelector(state) {
  return isEnabledMoreCampaignObjectivesSelector(state);
}

export function showAudiencesSegmentationFormSelector(state) {
  return (
    !isDuplicatedCampaignSelector(state) &&
    !isLeadObjectiveSelector(state) &&
    !isConversionObjectiveSelector(state) &&
    !isObjective(state, OBJECTIVE_SMART_CLICKS)
  );
}

export function showReceiveLeadsFormSelector(state) {
  return isLeadObjectiveSelector(state);
}

export function showTrackingFormSelector(state) {
  return !isLeadObjectiveSelector(state);
}

export function isWebhookEnabledSelector(state) {
  return state.trovitData.isWebhookEnabled;
}

export function isConversionTrackingPixelInstalledSelector(state) {
  return state.trovitData.isConversionTrackingPixelInstalled;
}

export function showPixelConversionModalSelector(state) {
  return (
    !isConversionTrackingPixelInstalledSelector(state) &&
    isConversionObjectiveSelector(state)
  );
}

export function showLeadObjectiveInChooserSelector(state) {
  return state.trovitData.source.verticalId === VERTICAL_ID_HOMES;
}

export function showFeedbackFromAudienceCampaignsSelector(state) {
  return getFeatureSelector(
    state,
    'AUDIENCE_IN_RETIS_SHOW_FEEDBACK_FROM_CAMPAIGNS_WITH_AUDIENCE_SEGMENTATION'
  );
}

export function blankMNNSummaryValuesWhenAudienceBehaviorSelector(state) {
  const isThereAField = state.formData.userSegmentation.find(
    (criterion) => criterion.field === 'user_new'
  );
  return isThereAField || false;
}

export function blankMNNSummaryValuesWhenGeoCriteriaSelector(state) {
  return audienceGeoCriteriaSelector(state).length > 0;
}

export function isHomesTheCurrentVerticalSelector(state) {
  return state.trovitData.source.verticalId === VERTICAL_ID_HOMES;
}

export function isThreeDecimalsConfigSelector(state) {
  const decimals =
    state.formData.decimalsConfig === undefined
      ? 2
      : state.formData.decimalsConfig;
  return decimals === 3 && !isObjectiveClicksSelector(state);
}

export function shouldShowThreeDecimalsPopup(state) {
  return (
    isThreeDecimalsConfigSelector(state) &&
    !showPixelConversionModalSelector(state) &&
    state.isDismissedDecimalPrecision !== true
  );
}
